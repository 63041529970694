@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    src: url('./fonts/inter/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/inter/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
}

body {
    font-family: 'Inter', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @apply m-0 tracking-wider;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    @apply text-36px leading-48px md:text-54px md:leading-72px;
}

h2 {
    @apply text-24px leading-30px md:text-36px md:leading-48px lg:text-45px lg:leading-56px;
}

h3 {
    @apply text-24px leading-30px md:text-28px md:leading-36px lg:text-36px lg:leading-48px;
}

h4 {
    @apply text-20px leading-24px lg:text-28px lg:leading-36px;
}

h5 {
    @apply text-16px leading-20px lg:text-24px lg:leading-30px;
}

h6 {
    @apply text-16px leading-20px md:text-20px md:leading-24px;
}

.credits h6 {
    @apply mb-2 text-16px font-semibold leading-20px md:text-20px md:leading-24px;
}

.credits p {
    @apply mb-10;
}

.paragraph-large {
    @apply text-21px leading-26-25px;
}

.paragraph-medium {
    @apply text-16px leading-20px;
}

.link {
    @apply underline hover:text-bright-orange;
}

.link-large {
    @apply text-28px leading-36px;
}

.link-medium {
    @apply text-21px leading-24px;
}

.link-small {
    @apply text-18px leading-24px;
}

.link-bright-orange {
    @apply transition delay-75 ease-in-out hover:bg-bright-orange;
}

.link-light-grey {
    @apply hover:bg-light-gray;
}

.link-black {
    @apply opacity-80 hover:opacity-100;
}

.share-link {
    @apply px-4 py-2 text-left transition delay-150 ease-in-out hover:bg-light-gray;
}

.external-link::after {
    content: url('./svg/external-link.svg');

    @apply ml-2;
}

input[type='checkbox']:focus,
[type='radio']:focus,
[type='search']:focus {
    box-shadow: none;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

/* react-instantsearch-dom */
.ais-Pagination-list {
    @apply my-6 flex items-center justify-center gap-2 md:my-12 md:mb-10;
}

.ais-Pagination-item {
    @apply flex h-12 w-12 flex-col items-center justify-center text-16px leading-16px md:text-20px md:leading-24px;
}

.ais-Pagination-item--page:not(.ais-Pagination-item--selected) {
    @apply hidden md:flex;
}

.ais-Pagination-item--selected {
    @apply bg-bright-orange font-bold;
}

.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage {
    @apply text-3xl;
}

.ais-Pagination-item--firstPage,
.ais-RefinementList-checkbox,
.ais-CurrentRefinements-label {
    @apply hidden;
}

.ais-Pagination-item--disabled {
    @apply cursor-not-allowed opacity-30;
}

.ais-ClearRefinements-button {
    @apply max-w-md border border-black bg-black py-4 px-6 text-white hover:bg-light-gray xl:w-full xl:w-2/3 xl:max-w-none xl:bg-inherit xl:text-black;
}

.ais-RefinementList-item {
    @apply mb-2;
}

.ais-RefinementList-label {
    @apply cursor-pointer;
}

.ais-CurrentRefinements:not(.ais-CurrentRefinements--noRefinement) {
    @apply mb-12;
}

.ais-CurrentRefinements-category {
    @apply mb-2 block max-w-md px-6 text-18px leading-24px lg:text-21px xl:max-w-none xl:px-0;
}

.ais-CurrentRefinements-categoryLabel {
    @apply mr-2.5;
}

.ais-CurrentRefinements-delete {
    @apply font-bold;
}

.ais-RefinementList-count {
    @apply text-dark-orange;
}

.ais-RefinementList-item--selected {
    @apply text-dark-orange;
}

.ais-SortBy-select {
    @apply cursor-pointer py-4 pl-6 pr-10 text-14px leading-20px focus:border-black focus:ring-transparent md:text-21px md:leading-24px;
}

.hover-text {
    position: absolute;
    bottom: 100%;
    background-color: #555;
    width: fit-content;
    white-space: nowrap;
    color: white;
    padding: 3px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
}

.hover-text::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #555;
    position: absolute;
    top: 100%;
}


.show-filters {
    display: block !important;
}

/* Overrite inline styling for the excerpts  */
.excerpt * {
    font-weight: normal Im !important;
    font-size: 20px !important;
    line-height: 24px !important;
}

:root {
    --toastify-color-progress-light: #FF7C44;
    --toastify-color-error: #CA4208;
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-color-progress-error: var(--toastify-color-error);
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    html {
        -webkit-tap-highlight-color: transparent;
    }

    /*html, body {*/
    /*    overflow-x: hidden;*/
    /*}*/

    /*body {*/
    /*    position: relative*/
    /*}*/
}


